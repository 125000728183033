:root {
  --primary-color: #1da1f2;
  --primary-hover-color: #1a91da;
  --background-color: #15202b;
  --border-color: #38444d;
  --faded-theme-color: #f916803c;
  --gray-color: #888888;
  --blue-color: #1d9bf0;
  --text-color: #fff;
  --light-gray: #222;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #15202b;
  font-family: "Quicksand", sans-serif;
  font-size: "0.5rem";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;
}

button:disabled {
  cursor: not-allowed;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

input,
textarea {
  font-family: inherit;
}

span {
  display: block;
}

a {
  text-decoration: none;
}
